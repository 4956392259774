<template>
  <div class="products_page">
    <filters
      v-if="filters.account && stores.length && brands.length"
      :key="`${filters.account}_${filters.type}`"
      :filter-items="filterItems"
      @applyFilters="loadMainGoods" />
    <main class="content">
      <products-body
        v-if="filters.account && mainGoods.results && stores.length && brands.length"
        :key="filters.type"
        @loadMainGoods="loadMainGoods" />
    </main>
  </div>
</template>

<script>
import { RPC2SKU } from '@/js/constants';
import queryFunction from '@/js/queryFunction';
import LIST_DATES from '@/js/calendar-options';

import Filters from '@/assets/Filters';
import ProductsBody from './ProductsBody';
import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'Products',

  components: {
    Filters,
    ProductsBody,
  },

  data() {
    return {
      linked: [
        {
          value: null,
          name: this.$t('allGoods'),
        },
        {
          value: false,
          name: this.$t('unlinkOnly'),
        },
        {
          value: true,
          name: this.$t('linkOnly'),
        },
      ],

      types: [
        {
          value: 'rpc2sku',
          name: 'RPC - SKU',
        },
        {
          value: 'sku2rpc',
          name: 'SKU - RPC',
        },
      ],
    };
  },

  computed: {
    ...mapState([
      'accounts',
      'stores',
      'brands',
      'filters',
      'mainGoods',
      'modal',
      'updateColumsKey',
    ]),

    suppliers() {
      return [{
        id: 'all',
        name: this.$t('allSuppliers'),
      }, {
        id: 'own',
        name: this.$t('own'),
      }, {
        id: 'rival',
        name: this.$t('rival'),
      }];
    },

    fullListStores() {
      return [{ id: null, name: this.$t('all') }, ...this.stores];
    },

    filterItems() {
      let res = [];

      if (this.filters.type === RPC2SKU) {
        res = {
          level1: [
            { type: 'search', storeName: 'global_term', placeholder: this.$t('searchRPC') },
            { type: 'dropdown', storeName: 'type', options: this.types, isNeedSearch: false, optionLabel: 'name', optionValue: 'value', placeholder: '' },
          ],
          level2: [
            { type: 'multiSelect', storeName: 'stores', options: this.stores, isNeedSearch: true, optionLabel: 'name', optionValue: 'id', placeholder: this.$t('stores') },
            { type: 'dropdown', storeName: 'suppliers_type', options: this.suppliers, isNeedSearch: false, optionLabel: 'name', optionValue: 'id', placeholder: this.$t('suppliers') },
            { type: 'multiSelect', storeName: 'brands', options: this.brands, isNeedSearch: true, optionLabel: 'brand', optionValue: 'id', placeholder: this.$t('brands') },
            { type: 'dropdown', storeName: 'only_linked', options: this.linked, isNeedSearch: false, optionLabel: 'name', optionValue: 'value', placeholder: this.$t('type') },
            { type: 'slider', storeName: 'distance', range: true, placeholder: this.$t('match') },
            { type: 'calendar', storeName: 'actual_date', showIcon: true, selectionMode: 'range', dateFormat: 'dd-mm-yy', isReset: false, dateFrom: LIST_DATES[0].DATE_FROM, dateTo: LIST_DATES[0].DATE_TO, placeholder: this.$t('dateModified') },
            { type: 'calendar', storeName: 'created_date', showIcon: true, selectionMode: 'range', dateFormat: 'dd-mm-yy', isReset: true, placeholder: this.$t('dateCreated') },
          ],
        };
      } else {
        res = {
          level1: [
            { type: 'search', storeName: 'global_term', placeholder: this.$t('searchSKU') },
            { type: 'dropdown', storeName: 'type', options: this.types, isNeedSearch: false, optionLabel: 'name', optionValue: 'value', placeholder: '' },
          ],
          level2: [
            { type: 'multiSelect', storeName: 'stores', options: this.stores, isNeedSearch: true, optionLabel: 'name', optionValue: 'id', placeholder: this.$t('stores'), customMethod: 'resetStoresInStore' },
            { type: 'dropdown', storeName: 'suppliers_type', options: this.suppliers, isNeedSearch: false, optionLabel: 'name', optionValue: 'id', placeholder: this.$t('suppliers') },
            { type: 'multiSelect', storeName: 'brands', options: this.brands, isNeedSearch: true, optionLabel: 'brand', optionValue: 'id', placeholder: this.$t('brands') },
            { type: 'dropdown', storeName: 'only_linked', options: this.linked, isNeedSearch: false, optionLabel: 'name', optionValue: 'value', placeholder: this.$t('type') },
            { type: 'slider', storeName: 'distance', range: true, placeholder: this.$t('match') },
            { type: 'calendar', storeName: 'actual_date', showIcon: true, selectionMode: 'range', dateFormat: 'dd-mm-yy', isReset: false, dateFrom: LIST_DATES[0].DATE_FROM, dateTo: LIST_DATES[0].DATE_TO, placeholder: this.$t('dateModified') },
            { type: 'calendar', storeName: 'created_date', showIcon: true, selectionMode: 'range', dateFormat: 'dd-mm-yy', isReset: true, placeholder: this.$t('dateCreated') },
          ],
        };
      }

      return res;
    },
  },

  async created() {
    await this.checkRoute(this.$route.query);
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    ...mapActions([
      'GET_GOODS',
      'GET_GOODS_PIM',
      'GET_LINKED_GOODS',
    ]),

    // проверяем урл и распарсиваем его при заходе пользователя
    // не на дефолтную страницу, а уже с выбранными фильтрами в урле
    async checkRoute(query) {
      // функция по добавлению в стор основных данных по фильтрам
      query = queryFunction.prepareToStore(query);

      this.M_MUTATE_BY_KEY({
        key: 'filters',
        data: query,
        create: true,
      });
    },

    // Загрузка 1 столбца
    async loadMainGoods(filters) {
      if (this.filters.type === RPC2SKU) {
        await this.GET_GOODS({ body: filters });
      } else {
        await this.GET_GOODS_PIM({ body: filters });
      }

      // Если есть выбранный товар то кликнуть по ниму
      // При поиске/пагинации в 1 столбце обновлять другие столбцы не надо
      if (this.filters.selectedId && !filters.isFirstColumn) {
        this.M_MUTATE_BY_KEY({
          key: 'updateColumsKey',
          data: this.updateColumsKey + 1,
        });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.products_page
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  min-height: 0;

.content
  flex: 1 0 0
  min-height: 0
  padding: 0 16px
</style>