<template>
  <div class="filters-main">
    <div class="row">
      <div class="filters">
        <template v-for="(item, index) of filterItems.level1">
          <!-- ПОИСК -->
          <search
            v-if="item.type === 'search'"
            :key="`${index}_1`"
            class="filter-item search-container"
            :local-filters="localFilters"
            :data="item"
            @eventInput="inputSearch"
            @eventClick="clearSearch($event)" />

          <!-- ДропДаун RPC-SKU или SKU-RPC-->
          <dropdown
            v-if="item.type === 'dropdown' && item.storeName === 'type'"
            :key="`${index}_1`"
            v-model="localFilters[item.storeName]"
            class="filter-item"
            :options="item.options"
            :filter="item.isNeedSearch"
            :option-label="item.optionLabel"
            :option-value="item.optionValue"
            :placeholder="item.placeholder"
            @change="changeType(localFilters[item.storeName])" />
        </template>

        <!-- Кнопка ФИЛЬТРЫ -->
        <button-prime
          :class="['filters_btn', { active: isLevel2Open }] "
          @click="isClickFilter2Lvl">
          <span class="mr5">
            {{ $t('filters') }}
          </span>
          <i class="ml5 fa-solid fa-filter" />
        </button-prime>
      </div>

      <!-- Кнопка массовых операций или еще чего то -->
      <slot />
    </div>

    <div
      v-if="isLevel2Open"
      class="separator" />

    <div
      v-if="isLevel2Open"
      class="row">
      <div class="filters">
        <template v-for="(item, index) of filterItems.level2">
          <!-- ДропДаун -->
          <dropdown
            v-if="item.type === 'dropdown'"
            :key="`${index}_2`"
            v-model="localFilters[item.storeName]"
            class="filter-item"
            :options="item.options"
            :filter="item.isNeedSearch"
            :option-label="item.optionLabel"
            :option-value="item.optionValue"
            :placeholder="item.placeholder"
            @change="applyFilters(localFilters)" />

          <!-- Множественный выбор -->
          <multi-select
            v-if="item.type === 'multiSelect'"
            :key="`${index}_2`"
            v-model="localFilters[item.storeName]"
            class="filter-item"
            :options="item.options"
            :filter="item.isNeedSearch"
            :option-label="item.optionLabel"
            :option-value="item.optionValue"
            :placeholder="item.placeholder"
            @change="eventMultiSelect(localFilters, item.customMethod||'')" />

          <!-- Слайдер -->
          <slider-filter
            v-if="item.type === 'slider'"
            :key="`${index}_2`"
            class="filter-item slider"
            :local-filters="localFilters"
            :data="item"
            @applySlider="applySlider" />

          <!-- Календарь -->
          <calendar
            v-if="item.type === 'calendar'"
            :key="`${index}_2`"
            v-model="calendars[item.storeName]"
            class="filter-item"
            :show-icon="item.showIcon"
            :selection-mode="item.selectionMode"
            :date-format="item.dateFormat"
            :placeholder="item.placeholder"
            :show-button-bar="item.showButtonBar"
            @today-click="todayClick($event, item.storeName)"
            @hide="hideCalendar(item.storeName)"
            @date-select="applyCalendarFilter(calendars[item.storeName], `${item.storeName}`)" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/js/debounce';
import queryFunction from '@/js/queryFunction';
import buttonPrime from 'primevue/button';
import Search from '@/assets/Search';
import sliderFilter from '@/assets/sliderFilter';

import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Filters',

  components: {
    buttonPrime,
    Search,
    sliderFilter,
  },

  props: {
    // Список фильтров на странице
    filterItems: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      calendars: {
        actual_date: [],
        created_date: [],
        reviewed_date: [],
      },

      localFilters: {},
    };
  },

  computed: {
    ...mapState([
      'stores',
      'brands',
      'filters',
      'isLevel2Open',
    ]),
  },

  async created() {
    this.setDataToFilter();
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    isClickFilter2Lvl() {
      this.M_MUTATE_BY_KEY({
        key: 'isLevel2Open',
        data: !this.isLevel2Open,
      });
    },

    todayClick(e, key) {
      this.calendars[key] = [e, e];
      this.applyCalendarFilter(this.calendars[key], `${key}`);
    },

    inputSearch: debounce(function () { this.applyFilters(this.localFilters); }, 500),

    // Активен только если выбран товар в 1 столбце
    applySlider: debounce(function () { this.applyFilters(this.localFilters); }, 500),

    // При первой загрузке фильров selectedId не сбрасываем
    setDataToFilter() {
      this.localFilters = { ...this.filters };

      for (const key in this.filterItems) {
        const levelFilters = this.filterItems[key];

        // Проходимся по всем календарям всех уровней
        levelFilters.forEach(element => {
          if (element.type === 'calendar') {
            this.calendars[element.storeName] = null;

            let dataA = this.filters[element.storeName + '_from'];
            let dataB = this.filters[element.storeName + '_to'];

            // Если в фильтрах в сторе нет дат, И нужно проставить дефолтные
            // И ДЕФОЛТНЫЕ ТОЖЕ ЕСТЬ
            if (!dataA && !dataB && element.dateFrom && element.dateTo) {
              dataA = element.dateFrom;
              dataB = element.dateTo;

              this.M_MUTATE_BY_KEY({
                key: `filters.${element.storeName + '_from'}`,
                data: dataA,
                create: true,
              });

              this.M_MUTATE_BY_KEY({
                key: `filters.${element.storeName + '_to'}`,
                data: dataB,
                create: true,
              });

              this.localFilters[element.storeName + '_from'] = queryFunction.dateToFormat(dataA);
              this.localFilters[element.storeName + '_to'] = queryFunction.dateToFormat(dataB);

              dataA = element.dateFrom;
              dataB = element.dateTo;
            }

            if (dataA && dataB) {
              this.calendars[element.storeName] = [new Date(dataA), new Date(dataB)];
            }
          }
        });
      }

      this.resetStoresInStore(this.localFilters);

      this.applyFilters(this.localFilters, true);
    },

    applyCalendarFilter(date, key) {
      this.localFilters[key + '_from'] = queryFunction.dateToFormat(date[0]);
      this.localFilters[key + '_to'] = queryFunction.dateToFormat(date[1]);

      // Если выбранны 2 даты то отправлять запрос
      if (date[0] && date[1]) {
        this.applyFilters(this.localFilters);
      }
    },

    changeType(type) {
      let defaultFilters = {};

      if (type === 'rpc2sku') {
        // Страница  rpc2sku
        defaultFilters = queryFunction.defaultFiltersRpc2Sku(this.filters.account);
      } else if (type === 'sku2rpc') {
        // Страница sku2rpc
        defaultFilters = {
          account: this.filters.account,
          type: 'sku2rpc',
          stores: [+this.stores[0].id],
          brands: [],
          only_linked: null,
          global_term: '',
          sub_term: '',
          distance: [70, 100],
          selectedId: null,
          page: 1,
        };

        defaultFilters[`store_${this.stores[0].id}_search_algorithm`] = 'name';
      }

      this.applyFilters(defaultFilters);
    },

    hideCalendar(key) {
      const isReset = this.filterItems.level2.find(el => el.storeName === key).isReset || false;
      const dates = this.calendars[key];

      // возможно ли сбросить календарь полностью
      if (isReset) {
        // если выбрана только 1 дата, то сбросить календарь
        if ((!dates || !dates[0] || !dates[1])) {
          this.calendars[key] = null;
          this.localFilters[key + '_from'] = undefined;
          this.localFilters[key + '_to'] = undefined;

          this.applyFilters(this.localFilters);
        }
      } else {
        // если чел пытается сбросить, но сбросить нельзя - то восстанавливаем прежние даты
        this.localFilters[key + '_from'] = queryFunction.dateToFormat(this.filters[key + '_from']);
        this.localFilters[key + '_to'] = queryFunction.dateToFormat(this.filters[key + '_to']);

        this.calendars[key] = [new Date(this.filters[key + '_from']), new Date(this.filters[key + '_to'])];
      }
    },

    eventMultiSelect(filters, nameMethod) {
      if (nameMethod.length) {
        this[nameMethod](filters);
      }

      this.applyFilters(filters);
    },

    // актуализиуруем магазины в сторе для sku2rpc
    resetStoresInStore(filters) {
      if (!('stores' in filters)) {
        return;
      }

      const sku2rpcStores = {};

      filters.stores.forEach((store) => {
        const obj = {
          results: [],
          count: 0,
          id: store,
          name: this.stores.find(el => el.id === store).name,
        };

        sku2rpcStores[store] = obj;
      });

      this.M_MUTATE_BY_KEY({
        key: 'sku2rpcStores',
        data: sku2rpcStores,
      });
    },

    // применение всех фильтров
    async applyFilters(filters, isFirstLoad = false) {
      let object = { ...filters };
      // Если загрузка не первая то сбрасываем столбцы,пагинацию,поиск
      if (!isFirstLoad) {
        // Очищаем столбцы от старых результатов
        this.M_MUTATE_BY_KEY({
          key: 'rpc2skuCandidates',
          data: {
            results: [],
            count: null,
          },
        });

        this.M_MUTATE_BY_KEY({
          key: 'rpc2skuLinked',
          data: {
            results: [],
            count: null,
          },
        });

        this.resetStoresInStore(filters);

        if (filters.type !== 'linksManager') {
          object.selectedId = null;
          object.page = 1;
        }
      }
      // Актуализируем фильтры в стор
      object = queryFunction.prepareToStore(object);

      // На странице скю-рпс при изменение магазинов нужно по дефолту создовать алгоритм поиска для каждого магазина
      if (object.type === 'sku2rpc') {
        object.stores.forEach(id => {
          if (!(`store_${id}_search_algorithm` in object)) {
            object[`store_${id}_search_algorithm`] = 'name';
          }
        });
      }

      setTimeout(() => {
        this.M_MUTATE_BY_KEY({
          key: 'filters',
          data: object,
        });

        this.$emit('applyFilters', object);
      }, 0);
    },

    clearSearch(storeName) {
      this.localFilters[storeName] = '';
      this.applyFilters(this.localFilters);
    },
  },
};
</script>

<style lang="stylus" scoped>
.filters-main
  padding: 24px 16px

.row
  display: flex
  align-items: center
  margin: 0

.separator
  margin: 12px 0px
  height: 1px
  background: $blue-grey-100

.filters
  display: flex
  align-items: center
  gap: 8px
  margin: 0

.filter-item, .filter-item-last
  padding: 0
  margin: 0

.slider
  flex-basis: 200px
  width: 200px

.filter-item-title
  font-size: 13px
  margin-bottom: 10px

::v-deep .p-dropdown,
::v-deep .p-multiselect
  width: 160px

::v-deep .p-dropdown .p-dropdown-panel,
::v-deep .p-multiselect .p-multiselect-panel
  min-width: 160px

::v-deep .p-dropdown-label,
::v-deep .p-inputtext,
::v-deep .p-multiselect,
::v-deep .p-calendar,
::v-deep .p-datepicker-trigger
  font-size: 14px

::v-deep .p-dropdown-filter,
::v-deep .p-dropdown-item,
::v-deep .p-multiselect-item
  font-size: 13px

::v-deep .p-dropdown .p-dropdown-panel
  border: 1px solid $blue-grey-100
  box-shadow: 0 2px 4px rgba(120, 144, 156, 0.5)
  border-radius: 4px

::v-deep .p-dropdown:not(.p-disabled).p-focus
  box-shadow: none

::v-deep .p-multiselect:not(.p-disabled):hover
  box-shadow: none

::v-deep .p-inputtext:enabled:focus
  box-shadow: none

.filters_btn
  height: 32px
  color: $white
  font-size: 14px
  background: $blue-500
  .fa-filter
    color: $white
    margin-left: 5px
&.active
  background: $blue-700
&:focus
  box-shadow: none !important
</style>