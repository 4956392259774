<template>
  <div class="header">
    <div class="row">
      <div class="logo">
        <img :src="STATIC_URL + 'logo.svg'">
      </div>
      <div
        v-show="accounts.length>1"
        class="accounts">
        <dropdown
          v-model="account"
          class="account-select"
          :placeholder="$t('selectAcc')"
          :options="accounts"
          :filter="true"
          option-label="shortname"
          option-value="shortname"
          @change="eventHeader">
          <template #value="slotProps">
            <template v-if="slotProps.value">
              {{ $t('acc') }} {{ slotProps.value }}
            </template>
            <template v-else>
              {{ slotProps.placeholder }}
            </template>
          </template>
        </dropdown>
      </div>

      <div
        v-show="accounts.length>1"
        class="separator" />

      <group-btn
        v-if="account"
        :key-active-btn="modernType"
        :list="types"
        @change="openTabs($event)" />
    </div>

    <!-- ДропДаун с сервисами Brandquad-->
    <dropdown
      v-if="integration"
      v-model="sevices"
      class="dropdown_sevices"
      :options="optionsServices"
      option-label="name"
      option-value="link">
      <template slot="value">
        <template>
          {{ $t('brandquadServices') }}
        </template>
      </template>
      <template #option="slotProps">
        <a
          :href="slotProps.option.link">
          {{ slotProps.option.name }}
        </a>
      </template>
    </dropdown>

    <a
      v-if="integration"
      :href="backToPim"
      class="link_admin">
      <i class="far fa-user-circle" />
      {{ username }}
    </a>
    <div
      v-else
      class="link_admin">
      <i class="far fa-user-circle" />
      {{ username }}
    </div>
  </div>
</template>

<script>
import { STATIC_URL } from '@/js/constants';
import { mapState, mapMutations, mapActions } from 'vuex';
import GroupBtn from '@/assets/GroupBtn';
import queryFunction from '@/js/queryFunction';
import { vars } from '@/js/additions';

export default {
  name: 'AppHeader',

  components: {
    GroupBtn,
  },

  data() {
    return {
      STATIC_URL,
      account: null,
      localType: 'products',
      sevices: '',

      optionsServices: [
        { name: this.$t('pimCatalog'), link: location.origin },
        { name: 'DSA', link: location.origin + '/buyfeedback/' },
      ],
    };
  },

  computed: {
    ...mapState({
      accountStore: state => state.filters.account,
      type: state => state.filters.type,
      username: state => state.userProfile.username,
      accounts: state => state.accounts,
      permissions: state => state.userProfile.permissions,
      stores: state => state.stores,
    }),

    modernType() {
      return this.type === 'rpc2sku' || this.type === 'sku2rpc' ? 'products' : this.type;
    },

    integration() {
      return vars.integration;
    },

    backToPim() {
      return location.origin + '/account/profile/info/';
    },

    isCanReadManageLinks() {
      let res = false;
      if ('can_read_manage_links_table' in this.permissions) {
        res = this.permissions.can_read_manage_links_table;
      }

      return res;
    },

    types() {
      const res = [
        { name: this.$t('products'), key: 'products' },
      ];

      if (this.isCanReadManageLinks) {
        res.push({ name: this.$t('manageLinks'), key: 'linksManager' });
        res.push({ name: this.$t('issue'), key: 'issue' });
      }

      return res;
    },
  },

  watch: {
    async accountStore(account) {
      await this.loadBrandsStores(account);
    },
  },

  async created() {
    if (this.accountStore) {
      this.account = this.accountStore;
      await this.loadBrandsStores(this.account);
    } else {
      if (this.accounts.length === 1) {
        this.account = this.accounts[0].shortname;
        await this.loadBrandsStores(this.account);
        this.eventHeader();
      }
    }
  },

  methods: {
    ...mapMutations([
      'M_MUTATE_BY_KEY',
    ]),

    ...mapActions([
      'GET_BRANDS',
      'GET_STORES',
    ]),

    openTabs(key) {
      this.localType = key;
      this.eventHeader();
    },

    // переключение аккаунта
    async eventHeader() {
      // Если календари пустые устанавливаем диапазон неделю по умолчанию
      const week = new Date();
      week.setDate(week.getDate() - 7);
      // Сбрасываем все фильтры
      let defaultFilters = {};

      if (this.localType === 'products') {
        // Страница по умолчанию rpc2sku
        defaultFilters = queryFunction.defaultFiltersRpc2Sku(this.account);
      } else if (this.localType === 'linksManager' || this.localType === 'issue') {
        // Страница linksManager или issue
        defaultFilters = {
          account: this.account,
          type: this.localType,
          stores: [],
          brands: [],
          issuer: [],
          status: '',
          global_term: '',
          created_date_from: undefined,
          created_date_to: undefined,
          reviewed_date_from: undefined,
          reviewed_date_to: undefined,
        };
      }

      this.M_MUTATE_BY_KEY({
        key: 'filters',
        data: defaultFilters,
      });

      // сбрасываем данные по столбцам
      this.M_MUTATE_BY_KEY({
        key: 'rpc2skuCandidates',
        data: {
          results: [],
          count: null,
          pages: 0,
        },
      });

      this.M_MUTATE_BY_KEY({
        key: 'rpc2skuLinked',
        data: {
          results: [],
          count: null,
          pages: 0,
        },
      });
    },

    // получаем данные по брендам и магазинам
    async loadBrandsStores(account) {
      await this.GET_STORES({ account });
      await this.GET_BRANDS({ account });
    },
  },
};
</script>

<style lang="stylus" scoped>
.header
  display: flex
  border-bottom: 1px solid $blue-grey-100

.row
  display: flex
  align-items: center
  flex: 1 0 0

.separator
  width: 1px
  height: 14px
  border-right: 1px solid $blue-grey-100

.logo
  padding: 16px
  border-right: 1px solid $blue-grey-100
  img
    display: block
    width: 112px
    height: 32px

.accounts
  display: flex
  align-items: center
  padding: 8px 20px
  font-weight: 500
  font-size: 14px
  line-height: 16px

::v-deep .p-inputtext
  color:  $blue-grey-400 !important

::v-deep .p-dropdown
  .p-dropdown-trigger
    width: auto

::v-deep .p-dropdown:not(.p-disabled).p-focus
  border-color: transparent
  box-shadow: none

::v-deep .p-inputtext:enabled:focus
  box-shadow: none

::v-deep .p-dropdown
  border: none

::v-deep .p-dropdown .p-dropdown-label
  font-weight: 500
  font-size: 14px
  &.p-inputtext
    padding-left: 0

::v-deep .p-dropdown-header
  padding: 8x

::v-deep .p-dropdown-filter, ::v-deep .p-dropdown-item
  font-size: 13px

::v-deep .p-dropdown-panel
  border: 1px solid $blue-grey-100
  box-shadow: 0 2px 4px rgba(120, 144, 156, 0.5)
  border-radius: 4px

.link_admin
  color: $blue-grey-700
  font-weight: 500
  padding: 16px
  display: flex
  align-items: center
  text-decoration: none
  .fa-user-circle
    font-size: 24px
    line-height: 24px
    color: $blue-grey-700
    margin-right: 5px

.dropdown_sevices
  display: flex
  align-items: center
  margin-right: 5px
</style>